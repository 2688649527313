import React, { useState } from 'react';
import EvInput from './subcomponents/EvInput';
import ConvInput from './subcomponents/ConvInput';
import DurationInput from './subcomponents/DurationInput';
import MileageInput from './subcomponents/MileageInput';
import Charts from './Charts';

import { 
    evEfficiencyCalc, 
    evFuelCostCalc, 
    evTCOCalc,
    convFuelCostCalc, 
    convTCOCalc, 
    roadTaxCalc, 
    totalInsuranceCalc, 
    serviceMaintainenceCalc, 
    depreciationAdjustmentCalc,
    purchaseCostLostCalc
} from '../calculations';

function Form() {

    let data = require('../data/data.json');
    
    const [ev, setEv] = useState(false);
    const [conv, setConv] = useState(false);
    const [duration, setDuration] = useState("5");
    const [mileage, setMileage] = useState("10000");

    const assumptions = data["Assumptions"];
    const pumpPrices = data["Pump Price"];

    function handleEvChange(e) {
        setEv(JSON.parse(e.currentTarget.value));
    }

    function handleEvRemove(){
        setEv(false);
    }

    function handleConvChange(e) {
        setConv(JSON.parse(e.currentTarget.value));
    }

    function handleConvRemove() {
        setConv(false);
    }

    function handleDurationChange(e) {
        setDuration(e.currentTarget.value);
    }

    function handleMileageChange(e) {
        setMileage(e.currentTarget.value);
    }

    let evFuelCost;
    let evEfficiency;
    let evTotalInsurance;
    let evRealRange;
    let evDepreciationAdjustment;
    let evLostCost;
    let evTCO;

    let serviceMaintainence = 0;
    
    if(ev && conv) {
        let annualMaintainence = assumptions["annualMaintainence"];
        serviceMaintainence = serviceMaintainenceCalc(annualMaintainence, duration);

        let evBatterySize = ev["Useable battery size (kWh)"];
        evRealRange = ev["Real range (miles)"];
        evEfficiency = evEfficiencyCalc(evBatterySize, evRealRange);

        let elecKwh = assumptions["Electricity Cost"];
        evFuelCost = evFuelCostCalc(duration, evEfficiency, elecKwh, mileage);
        
        let annualEvInsurance = ev["Annual Insurance"];
        evTotalInsurance = totalInsuranceCalc(annualEvInsurance, duration);

        let evType = "electric";
        evDepreciationAdjustment = depreciationAdjustmentCalc(duration, evType);

        let evPurchasePrice = ev["New Purchase Price"];
        evLostCost = purchaseCostLostCalc(evPurchasePrice, evDepreciationAdjustment);

        evTCO = evTCOCalc(evDepreciationAdjustment, evPurchasePrice, evFuelCost, evTotalInsurance, serviceMaintainence);
    }

    let pumpPrice = 0;
    let convFuelCost = 0;
    let convTotalInsurance = 0;
    let roadTax = 0;
    let convDepreciationAdjustment = 0;
    let convLostCost = 0;
    let convTCO = 0;
    let mpg = 0;

    if(ev && conv){

        if(conv["Fuel_1"] === "Diesel") {
            pumpPrice = pumpPrices["Diesel"];
        } else if (conv["Fuel_1"] === "Petrol") {
            pumpPrice = pumpPrices["Petrol"];
        }

        mpg = conv["Fuel efficiency (mpg)"];
        convFuelCost = convFuelCostCalc(mileage, duration, pumpPrice, mpg);

        let annualConvInsurance = conv["Annual Insurance"];
        convTotalInsurance = totalInsuranceCalc(annualConvInsurance, duration);

        let initialRoadTax = conv["Tax Payment (CO2 dependent, first time)"];
        let subsequentRoadTax = conv["Annual road tax (after 12 months)"];
        roadTax = roadTaxCalc(initialRoadTax, subsequentRoadTax, duration);

        let convType = "conventional";
        convDepreciationAdjustment = depreciationAdjustmentCalc(duration, convType);

        let convPurchasePrice = conv["Purchase price"];
        convLostCost = purchaseCostLostCalc(convPurchasePrice, convDepreciationAdjustment)

        convTCO = convTCOCalc(convPurchasePrice, convDepreciationAdjustment, convTotalInsurance, roadTax, serviceMaintainence, convFuelCost);
    }
    
    let difference = 0;
    let diffPurchase = 0;
    let diffInsurance = 0;
    let diffFuel = 0;

    if(ev && conv) {
        difference = Math.round(evTCO) - Math.round(convTCO);

        diffPurchase = evLostCost - convLostCost;

        diffInsurance = evTotalInsurance - convTotalInsurance;
        diffFuel = evFuelCost - (convFuelCost + roadTax);
    }

    return (
        <>
            <EvInput ev={ev} handleEvChange={handleEvChange} handleEvRemove={handleEvRemove} />
            <ConvInput ev={ev} conv={conv} handleConvChange={handleConvChange} handleConvRemove={handleConvRemove} />
            <DurationInput duration={duration} handleDurationChange={handleDurationChange} />
            <MileageInput mileage={mileage} handleMileageChange={handleMileageChange} />
            <Charts difference={difference} diffFuel={diffFuel} diffInsurance={diffInsurance} diffPurchase={diffPurchase} />
        </>
    );
}

export default Form;