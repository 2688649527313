function EvInput({ev, handleEvChange, handleEvRemove}) {

    let data = require('../../data/data.json');

    return(
        <>
            {!ev ?
                <div className="f-item row no-gutters mb-20 w-100 bg-white d-flex py-30 px-20 align-items-center justify-content-start">
                    <div className="bg-primary d-flex justify-content-center align-items-center dot mr-20">
                        <span className="font-weight-bold">1</span>
                    </div>
                    <label className="f-label f-label__before col-8 d-block js-input-label mr-20 label-collapse">
                        Please choose the EV that you are interested in
                    </label>
                    <div className="f-type__select col-12 select-collapse">
                        <div className="f-group js-form-field">
                            <select className="f-select__element f-select__arrow js-input_field"
                                id="select"
                                name="select"
                                onChange={handleEvChange}
                                defaultValue="Select Vehicle"
                            >
                                <option value="Select Vehicle" disabled>Select Vehicle</option>
                                {Object.values(data.EV_data).map((object, index) =>
                                    <option
                                        value={JSON.stringify(object)}
                                        key={index}
                                    >
                                        {object["Generic Model"] + ' - ' + object["Model"]}
                                    </option>
                                )}
                            </select>
                        </div>
                    </div>
                </div>
            : 
                <div className="f-item row no-gutters mb-20 w-100 bg-white d-flex py-30 px-20 align-items-center justify-content-between no-wrap vehicle-selected">
                    <div className="d-flex justify-content-start col-11 align-items-center car-info">
                        <div className="bg-primary d-flex justify-content-center align-items-center dot mr-20">
                            <span className="font-weight-bold">1</span>
                        </div>
                        <div className="icon-multicolor icon-car-front mr-20"></div>
                        <div className="d-block js-input-label small-screen-width ev-small-screen">
                            <p className="f-label f-label__before mb-0">{ev["Generic Model"] + ' - ' + ev["Model"]}</p>
                            <p className="mb-0 info-darker-fanatic">Purchase Price: <span className="font-weight-bold">£{new Intl.NumberFormat('en-UK').format(ev["Purchase Price"])}</span></p>
                            <p className="mb-0 info-darker-fanatic">Real Range: <span className="font-weight-bold">{ev["Real range (miles)"]} Miles</span></p>
                        </div>
                    </div>
                    <div className="border dot-large d-flex justify-content-center align-items-center" onClick={handleEvRemove}>
                        &#10005;
                    </div>
                </div>
            }
        </>
    );

}

export default EvInput;