import './styles/css/styles.min.css';
import Main from './components/Main';

const App = () => {
  return (
    <Main />
  );
}

export default App;
