function DurationInput({duration, handleDurationChange}) {

    return(
        <div className="f-item row no-gutters mb-20 w-100 d-flex py-30 px-20 align-items-center justify-content-start">
            <div className="bg-primary d-flex justify-content-center align-items-center dot mr-20">
                <span className="font-weight-bold">3</span>
            </div>
            <label className="f-label f-label__before d-block js-input-label mr-20 range-label-collapse">
                Please choose the length of time you would keep the car
            </label>
            <div className="f-type__select col-12 flex-fill mr-20 range-collapse">
                <div className="f-group js-form-field">
                    <input 
                        type="range" 
                        id="duration" 
                        name="duration" 
                        min="1" 
                        max="10"
                        value={duration}
                        onChange={handleDurationChange} 
                    />
                </div>
            </div>
            <div className="col-12 years-mileage-collapse pt-20 text-center">
                <span className="b-heading__primary-3">{duration} <span className="text-gray-900">Years</span></span>
            </div>
        </div>
    );

}

export default DurationInput;