import Form from './Form';

function Main(props) {

    return (
        <div className="l-wrapper">
            <div data-cmp="contentPage" className="h-container b-page b-cmp__loaded">
                <div className="container">
                    <div className="f-item row no-gutters mb-20 w-100 d-flex justify-content-center py-20">
                        <span className="b-heading__primary-1">TCO Tool</span>
                    </div>
                    <div className="b-page__content mb-35 bg-gray-200 py-30 px-10 container">
                        <div className="row">
                            <div className="col-12 collapse-left-large-screen pb-20">
                                <div className="col-12 pb-20 collapse-left-large-screen text-center"><span className="b-heading__primary-3">Electric Vehicle Cost Comparison</span></div>
                                <div className="col-12 pb-10 collapse-left-large-screen"><h4 className="font-weight-normal">Many people are put off buying Electric Vehicles because of the cost. It’s true that in many cases the headline price of a new electric car is higher than the nearest equivalent petrol, diesel and indeed hybrid alternative.</h4></div>
                                <div className="col-12 pb-10 collapse-left-large-screen"><h4 className="font-weight-normal">But when you take into account the total cost of ownership, including fuel, insurance, government grants, and depreciation, a different picture often emerges.</h4></div>
                                <div className="col-12 pb-10 collapse-left-large-screen"><h4 className="font-weight-normal">This calculator reveals all!</h4></div>
                                <div className="col-12 pb-10 collapse-left-large-screen"><h4 className="font-weight-normal">How to use the calculator:</h4></div>
                                <div className="col-12 collapse-left-large-screen"><h4 className="font-weight-normal">1. Select from one of 20 of the biggest selling electric vehicles</h4></div>
                                <div className="col-12 collapse-left-large-screen"><h4 className="font-weight-normal">2. Then select one of the petrol, diesel or hybrid alternatives</h4></div>
                                <div className="col-12 collapse-left-large-screen"><h4 className="font-weight-normal">3. Use the bar to select anticipated length of ownership</h4></div>
                                <div className="col-12 pb-10 collapse-left-large-screen"><h4 className="font-weight-normal">4. Use the bar to select average annual mileage</h4></div>
                                <div className="col-12 collapse-left-large-screen"><h5 className="font-weight-normal font-italic">Calculations assume outright purchase of vehicle</h5></div>
                                <div className="col-12 collapse-left-large-screen"><h5 className="font-weight-normal font-italic">Vehicle pricing information accurate as of February 2021</h5></div>
                                <div className="col-12 collapse-left-large-screen"><h5 className="font-weight-normal font-italic">Plug in grant data accurate as per Government announcement of 18 March 2021</h5></div>
                                <div className="col-12 collapse-left-large-screen"><h5 className="font-weight-normal font-italic">Insurance based on cheapest quote obtained for a hypothetical individual with associated household information</h5></div>
                            </div>
                            <div className="col-12 pl-20 pr-20 collapse-right-large-screen">
                                <Form />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Main;