function MileageInput({mileage, handleMileageChange}) {

    return(
        <div className="f-item row no-gutters mb-20 w-100 d-flex py-30 px-20 align-items-center justify-content-start">
            <div className="bg-primary d-flex justify-content-center align-items-center dot mr-20">
                <span className="font-weight-bold">4</span>
            </div>
            <label className="f-label f-label__before d-block js-input-label mr-20 range-label-collapse">
                Please choose your expected annual mileage
            </label>
            <div className="f-type__select col-12 flex-fill mr-20 range-collapse">
                <div className="f-group js-form-field">
                    <input 
                        type="range" 
                        id="mileage" 
                        name="mileage" 
                        min="5000" 
                        max="25000"
                        step="5000"
                        value={mileage}
                        onChange={handleMileageChange} 
                    />
                </div>
            </div>
            <div className="col-12 years-mileage-collapse pt-20 text-center">
                <span className="b-heading__primary-3">{new Intl.NumberFormat('en-UK').format(mileage)} <span className="text-gray-900">m</span></span>
            </div>
        </div>
    );

}

export default MileageInput;