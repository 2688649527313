import Chart from 'chart.js';
import React, {useEffect} from "react";

let myChart2;

function TcoChart({difference}) {

    let xAxisLimit = Math.abs(difference);
    
    let max = 0;

    if(xAxisLimit < 500) {
        max = 500;
    } else if (xAxisLimit < 1000) {
        max = 1000;
    } else if (xAxisLimit < 2000) {
        max = 2000;
    } else if(xAxisLimit < 3000) {
        max = 3000;
    } else if (xAxisLimit < 4000) {
        max = 4000;
    } else if (xAxisLimit < 5000) {
        max = 5000;
    } else if (xAxisLimit < 6000) {
        max = 6000;
    } else if (xAxisLimit < 7000) {
        max = 7000;
    } else if (xAxisLimit < 8000) {
        max = 8000;
    } else if (xAxisLimit < 9000) {
        max = 9000;
    } else if (xAxisLimit < 10000) {
        max = 10000;
    } else if (xAxisLimit < 20000) {
        max = 20000;
    } else if (xAxisLimit < 30000) {
        max = 30000;
    } else if (xAxisLimit < 40000) {
        max = 40000;
    } else if (xAxisLimit < 50000) {
        max = 50000;
    } else if (xAxisLimit < 60000) {
        max = 60000;
    } else if (xAxisLimit < 70000) {
        max = 70000;
    } else if (xAxisLimit < 80000) {
        max = 80000;
    } else if (xAxisLimit < 90000) {
        max = 90000;
    } else {
        max = 100000;
    };

    let min = -Math.abs(max);
    
    useEffect(() => {
        if(myChart2) {
            myChart2.destroy();
        }

        const ctx = document.getElementById("myChart2");

        myChart2 = new Chart(ctx, {
            type: 'horizontalBar',
            options: {
                aspectRatio: 3,
                scales: {
                    xAxes: [{
                        ticks: {
                            callback: function(value) {
                                return '£' + value;
                            },
                            min: min,
                            max: max,
                            maxTicksLimit: window.innerWidth < 768 ? 3 : 7,
                            padding: 5
                        },
                        gridLines: {
                            zeroLineWidth: 5,
                            lineWidth: 0,
                            zeroLineColor: "#6e6e6e",
                            z: 1
                        }, 
                        // scaleLabel: {
                        //     display: window.innerWidth < 576 ? false : true,
                        //     labelString: "Total Cost of Ownership Difference"
                        // }                    
                    }],
                    yAxes: [{
                        gridLines: {
                            display: false
                        }                    
                    }]
                },
                legend: {
                    display: false
                },
                animation: {
                    duration: 1,
                    onComplete: function () {
                        var chartInstance = this.chart,
                        ctx = chartInstance.ctx;
                        ctx.textAlign = '';
                        ctx.fillStyle = "";
                        ctx.textBaseline = 'top';
                        ctx.font = window.innerWidth < 768 ? "10px aktiv-grotesk" : "20px aktiv-grotesk";

                        this.data.datasets.forEach(function (dataset, i) {
                            var meta = chartInstance.controller.getDatasetMeta(i);
                            meta.data.forEach(function (bar, index) {
                                var x = 0;
                                var y = 0;
                                if(dataset.data[index] > 0) {
                                    ctx.textAlign = 'left';
                                    ctx.fillStyle = '#E80200';
                                    x = bar._model.x + 2.5;
                                    y =window.innerWidth < 480 ? bar._model.y - 4 : bar._model.y - 5;
                                    ctx.font = window.innerWidth < 480 ? '8px Arial' : '';
                                } else {
                                    ctx.textAlign = 'right';
                                    ctx.fillStyle = '#37A703'; 
                                    x =bar._model.x - 2.5;
                                    y =window.innerWidth < 480 ? bar._model.y - 4 : bar._model.y - 5;
                                    ctx.font = window.innerWidth < 480 ? '8px Arial' : '';
                                }
                                var label = dataset.label;
                                ctx.fillText(label, x, y);
                            });
                        });

                        // chartInstance.options.aspectRatio = window.innerWidth < 576 ? 2 : 3;

                        chartInstance.options.scales.xAxes[0].ticks.maxTicksLimit = window.innerWidth < 768 ? 3 : 7;
                        // chartInstance.options.scales.xAxes[0].ticks.padding = window.innerWidth < 576 ? 5 : 10;
                        chartInstance.options.layout.padding.left = window.innerWidth < 768 ? 50 : 150;
                        chartInstance.options.layout.padding.right = window.innerWidth < 768 ? 50 : 150;
                    }
                },
                layout: {
                    padding: {
                        left: window.innerWidth < 768 ? 50 : 150,
                        right: window.innerWidth < 768 ? 50 : 150
                    }
                },
                tooltips: {
                    enabled: false
                },
                events: []
            },
            data: {
                datasets: [
                    {
                        label: `${difference > 0 ? `+£${difference}` : difference < 0 ? `-£${Math.abs(difference)} ` : ""}`,
                        data: [isNaN(difference) ? 0 : difference],
                        barPercentage: 0.4
                    }
                ]
            }
        });

        let bars = myChart2.data.datasets;
        for(let i = 0; i < bars.length; i += 1) {
            if(bars[i]['data'][0] > 0) {
                bars[i]["backgroundColor"] = "#E80200";
            } else {
                bars[i]["backgroundColor"] = "#37A703"; 
            }
        };
        myChart2.update();
    });
    return (
        <div className="App">
            <canvas id="myChart2" className={window.innerWidth < 576 ? "col-12 pl-0 pr-0" : "col-12"} />
        </div>
    );
    
}

export default TcoChart;