import TcoChart from './subcomponents/TcoChart';
import BreakdownChart from './subcomponents/BreakdownChart';

function Charts({difference, diffFuel, diffInsurance, diffPurchase}) {

    return(
        <div className="f-item row no-gutters mb-20 w-100 bg-white d-flex py-30 px-20 align-items-center justify-content-between">
            <div className="col-12 pb-20 text-left"><span className="b-heading__primary-3">Cost Comparison</span></div>
            <div className="col-12 pb-20 text-left"><span className="b-heading__primary-5">Total Cost of Ownership</span></div>
            <div className="chart-container-small">
                <TcoChart difference={difference} />
                { !difference ?
                    <>
                        <span className="minus-symbol-total">-</span>
                        <span className="plus-symbol-total">+</span>
                    </>
                : null
                }
            </div>
            <div className="col-12 pb-20 text-left"><span className="b-heading__primary-5">The Cost Difference Breakdown</span></div>
            <div className="chart-container">
                <BreakdownChart diffPurchase={diffPurchase} diffInsurance={diffInsurance} diffFuel={diffFuel} />
                { !diffFuel && !diffInsurance && !diffPurchase ?
                    <>
                        <span className="minus-symbol-break">-</span>
                        <span className="plus-symbol-break">+</span>
                    </>
                : null
                }
            </div>
            <span className="text-gray-900 w-100">* Note that costs displayed here are indicative only</span>
        </div>
    );

}

export default Charts;