// EV Functions

export function evEfficiencyCalc(batterySize, realRange) {
    return 1000 * (batterySize / realRange);
}

export function evFuelCostCalc(duration, evEfficiency, cost, mileage) {
    return duration * (evEfficiency * cost * mileage) / (100*1000);
}

export function evTCOCalc(depreciation, purchasePrice, fuelCost, totalInsurance, serviceMaintainence) {
    return depreciation * purchasePrice + fuelCost + totalInsurance + serviceMaintainence;
}

// Conventional Functions

export function convFuelCostCalc(mileage, duration, pumpPrice, mpg) {
        return 4.55 * parseInt(mileage) * parseInt(duration) * pumpPrice / (mpg * 100);
}

export function roadTaxCalc(initialRoadTax, subsequentRoadTax, duration) {
    let val;
    if(duration > 5){
        val =  Math.max((subsequentRoadTax - 325) * (duration - 5), 0);
    } else {
        val = 0; 
    }
    return initialRoadTax + subsequentRoadTax * Math.min(5,(duration - 1)) + val; 
}

export function convTCOCalc(depreciation, purchasePrice, totalInsurance, roadTax, serviceMaintainence, fuel ){
    return depreciation * purchasePrice + totalInsurance + roadTax + serviceMaintainence + fuel;
}

// General Functions

export function totalInsuranceCalc(insurance, duration) {
    return insurance * duration;
}

export function serviceMaintainenceCalc(annualMaintenance, duration) {
    return annualMaintenance * duration;
}

export function depreciationAdjustmentCalc(duration, type) {
    let data = require("./data/data.json");
    let match = {};
    if(type === "electric") {
        match = data["Depreciation"].filter(obj => obj["Year"] === parseInt(duration));
        return match[0]["Electric"] / 100;
    } else if(type === "conventional") {
        match = data["Depreciation"].filter(obj => obj["Year"] === parseInt(duration));
        return match[0]["Conventional"] / 100;
    }
}

export function purchaseCostLostCalc(price, depreciation) {
    return price * depreciation;
}